import styles from './OfertaAndPrivacy.module.scss'
import React from 'react'

function OfertaAndPrivacy({
  result,
  card_save_status,
  setCard_save_status,
  countSalesStatus
}) {
  const privacy = result?.privacy_url
    ? result?.privacy_url
    : result?.frame_companies?.find((el) => el)?.company_info?.privacy_url

  const oferta = result?.oferta_url
    ? result?.oferta_url
    : result?.frame_companies?.find((el) => el)?.company_info?.oferta_url

  const company_id = result?.frame_companies?.find((el) => el)?.company_id
  const rec_status = result?.recurrent_status ?? false
  const description = result?.description
  return (
    <>
      {result?.auth_type === 'C' && (
        <div className={styles.ofertaBox}>
          <input
            type="checkbox"
            className={styles.customCheckbox}
            id="save"
            name="save"
            value={card_save_status}
            checked={result?.bind_required || null}
            disabled={result?.bind_required}
            onChange={(e) => setCard_save_status(e.target.checked)}
            required
          />
          <label className={styles.oferta} htmlFor="save">
            <div className={styles.checkboxText}>
              Сохранить банковскую карту
            </div>
          </label>
        </div>
      )}
      {!countSalesStatus && (
        <>
          <div className={styles.ofertaBox}>
            <input
              type="checkbox"
              className={styles.customCheckbox}
              id="oferta"
              name="oferta"
              value="yes"
              required
            />
            <label className={styles.oferta} htmlFor="oferta">
              <div className={styles.checkboxText}>
                Я согласен(-на) на обработку{' '}
                <a href={privacy} target="_blank">
                  персональных данных
                </a>
              </div>
            </label>
          </div>
          <div className={styles.ofertaBox}>
            <input
              type="checkbox"
              className={styles.customCheckbox}
              id="oferta2"
              name="oferta2"
              value="yes"
              required
            />
            <label className={styles.oferta} htmlFor="oferta2">
              <div className={styles.checkboxText}>
                Я ознакомлен(-на) с условиями{' '}
                {company_id === 15 ? (
                  rec_status ? (
                    <a href={oferta} target="_blank">
                      публичной оферты на подписку
                    </a>
                  ) : (
                    <a href={oferta} target="_blank">
                      публичной оферты
                    </a>
                  )
                ) : (
                  <a href={oferta} target="_blank">
                    лицензионного договора
                  </a>
                )}
              </div>
            </label>
          </div>
          {description && (
            <div className={styles.ofertaBox}>
              <input
                type="checkbox"
                className={styles.customCheckbox}
                id="advertising"
                name="advertising"
                value="yes"
              />
              <label className={styles.oferta} htmlFor="advertising">
                <div className={styles.checkboxText}>
                  Я согласен(-на) на получение{' '}
                  <a href={description} target="_blank">
                    рекламных рассылок
                  </a>
                </div>
              </label>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default OfertaAndPrivacy
